/**
 * @name: index
 * @author: itmobai
 * @date: 2022-11-02 13:39
 * @description：index
 * @update: 2022-11-02 13:39
 */
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import storage from '@/storage'
import { loginApi, logoutApi, posLoginApi, refreshTokenApi } from '@/apis/user'
import { updatePwdApi, userInfoApi } from '@/apis/system'
import { IUser, IUserInfo } from '@/type/user'

export const useUserStore = defineStore("USER", () => {
  const tokenState = ref("");
  // 用户对象
  const userInfo = ref<IUserInfo>({});

  // 请求头token
  const token = computed(() => tokenState.value || storage.getData("TOKEN"))

  /**
   * 登录
   * @param data
   */
  const loginAction = async (data: IUser) => {
    return new Promise((resolve) => {
      loginApi(data).then(async e => {
        let { access_token } = e;
        // 存储token
        tokenState.value = access_token;
        storage.setData("TOKEN", access_token);

        resolve(e.stallList);
      })
    })
  }

  /**
   * 打单机登录
   * @param data 参数
   */
  const posLoginAction = async (data: any) => {
    return new Promise(resolve => {
      posLoginApi(data).then(async e => {
        let { access_token } = e;
        tokenState.value = access_token;

        const userInfoRes = await userInfoApi();
        userInfo.value = userInfoRes.user;
        resolve(null)
      })
    })
  }

  /**
   * 注销登录
   */
  const logoutAction = async () => {
    return new Promise(resolve => {
      logoutApi().then(() => {
        resolve(null);
      })
    })
  }

  /**
   * 更新密码
   * @param oldPwd 旧密码
   * @param newPwd 新密码
   */
  const updatePwdAction = async (oldPwd: string, newPwd: string) => {
    return new Promise(resolve => {
      updatePwdApi(oldPwd, newPwd).then(() => {
        tokenState.value = "";
        userInfo.value = {};
        resolve(null)
      })
    })
  }

  /**
   * 刷新token
   * @param userName
   * @param tenantId
   */
  const refreshTokenAction = async (userName: string, tenantId: string, mechantId: string) => {
    return await refreshTokenApi(userName, tenantId, mechantId);
  }

  /**
   * 设置token
   * @param token
   */
  const setToken = (token: string) => {
    tokenState.value = token;
  }

  /**
   * 设置userInfo
   * @param data userInfo
   */
  const setUserInfo = (data: IUserInfo) => {
    userInfo.value = data;
  }

  return {
    token,
    userInfo,
    setToken,
    setUserInfo,
    loginAction,
    logoutAction,
    refreshTokenAction,
    updatePwdAction,
    posLoginAction
  }
})
