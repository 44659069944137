/**
 * @name: index
 * @author: itmobai
 * @date: 2022-11-02 13:49
 * @description：index
 * @update: 2022-11-02 13:49
 */
import { postJ, postW, del } from '@/request'
import apiConfig from '@/config'
// 接口前缀
const apiPrefix = `/farm-auth/${apiConfig.apiMode}`

/**
 * 登录接口
 * @param data 登录参数
 */
export const loginApi = (data: any) => postJ({url: `${apiPrefix}/weighLogin`, data})
/**
 * 刷新用户token
 * @param userName 账号
 * @param tenantId 租户
 * @param merchantId 商户id
 */
export const refreshTokenApi = (userName: string, tenantId: string, merchantId: string) => postW({url: `${apiPrefix}/changeWeighToken?userName=${userName}&tenantId=${tenantId}&merchantId=${merchantId}`})

/**
 * 退出登录
 */
export const logoutApi = () => del({url: `${apiPrefix}/logout`})

/**
 * 打单机登录
 * @param data
 */
export const posLoginApi = (data: any) => postJ({url: `${apiPrefix}/login`, data})
