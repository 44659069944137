import { createApp } from 'vue'

import "@/utils/flexible"

import App from './App.vue'

import router from './router'
import { createPinia } from 'pinia'

import "normalize.css"

import '@/styles/base.scss'
import '@/styles/index.scss'
import "@/styles/icon.scss"

const app = createApp(App)
app.use(createPinia())
app.use(router)
app.mount("#app")
