/**
 * @name: index
 * @author: itmobai
 * @date: 2022-11-07 10:42
 * @description：index
 * @update: 2022-11-07 10:42
 */
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { ISettleShop } from '@/type/settle'
import { shopListApi, todayShopListApi, weightSaveApi } from '@/apis/core'

export const useShopStore = defineStore("SHOP", () => {
  /**
   * 获取商品列表
   * @param stallId 档口id
   */
  const shopListAction = async (stallId: string) => {
    return await shopListApi(stallId);
  }
  /**
   * 查询今日商品列表
   * @param stallId 档口id
   */
  const todayShopListAction = async (stallId: string) => {
    return await todayShopListApi(stallId);
  }

  /**
   * 保存称重记录
   * @param data 称重数据
   */
  const weightSaveAction = async (data: any) => {
    return await weightSaveApi(data);
  }

  return {
    shopListAction,
    todayShopListAction,
    weightSaveAction
  }
})
