/**
 * @name: index
 * @author: itmobai
 * @date: 2022-11-02 16:05
 * @description：index
 * @update: 2022-11-02 16:05
 */
import { get, postJ, postW } from '@/request'
import apiConfig from '@/config'
// 接口前缀
const apiPrefix = `/farm-core/${apiConfig.apiMode}`

/**
 * 档口列表
 */
export const stallListApi = () => get({url: `${apiPrefix}/fm_stall/listByUser`})

/**
 * 商品列表
 * @param stallId 档口id
 */
export const shopListApi = (stallId: string) => get({url: `${apiPrefix}/fm_stall_price/listByWeighting/${stallId}`})

/**
 * 获取付款二维码
 * @param stallId 档口id
 */
export const payPicApi = (stallId: string) => get({url: `${apiPrefix}/fm_stall/getPayCode/${stallId}`})

/**
 * 查询今日菜价
 * @param stallId 档口id
 */
export const todayShopListApi = (stallId: string) => get({url: `${apiPrefix}/fm_stall_price/list`, params: {stallId, isShow: 1}})

/**
 * 称重列表
 * @param params 查询参数
 */
export const weightListApi= (params: any) => get({url: `${apiPrefix}/fm_weighing/list`, params})

/**
 * 称重详情
 * @param id id
 */
export const weightDetailApi = (id: string) => get({url: `${apiPrefix}/fm_weighing/${id}`})

/**
 * 补打详情
 * @param id id
 */
export const replyApi = (id: string) => get({url: `${apiPrefix}/fm_weighing/reprint/${id}`})

/**
 * 保存称重记录
 * @param data 称重数据
 */
export const weightSaveApi = (data: any) => postJ({url: `${apiPrefix}/fm_weighing`, data})

/**
 * 查询打单机订单列表
 * @param params
 * @returns {Promise<unknown>}
 */
export const queryPrintMallOrderList = (params: any) => get({url: `${apiPrefix}/orderDistribution/print_mall_order_manage/queryPrintMallOrderList`, params})

/**
 * 小区下拉列表
 * @returns {Promise<unknown>}
 */
export const selectDeliveryScopeList = () => get({url: `${apiPrefix}/orderDistribution/print_mall_order_manage/selectDeliveryScopeList`})


/**
 * 查询订单
 * @returns {Promise<unknown>}
 */
export const orderDetailList = (params: any) => get({url: `${apiPrefix}/orderDistribution/print_mall_order_manage/queryMallOrderDetailList`,params})

/**
 * 查询小票详情
 * @param params
 * @returns {Promise<unknown>}
 */
export const queryOrderDetail = (params: any) => get({url: `${apiPrefix}/orderDistribution/print_mall_order_manage/getPrintMallOrderInfo`,params})

/**
 * 绑定设备码
 * @param deviceNo 设备码
 */
export const bindDeviceNo = (deviceNo: string) => postW({url: `${apiPrefix}/fm_tenant_device_no_detail/bindDeviceNo`, data: {deviceNo}})
/**
 * 解绑设备码
 * @param deviceNo 设备码
 */
export const unbindDeviceNo = (deviceNo: string) => postW({url: `${apiPrefix}/fm_tenant_device_no_detail/unbindDeviceNo`, data: {deviceNo}})
