import { h, createApp } from "vue";
import ErrDialogCom from "./Confirm.vue";

let node: any;

// 打开
const open = (title?: string, msg?: string) => {
  if (node) {
    document.body.removeChild(node);
  }
  node = document.createElement("div");

  const instance = createApp({
    render() {
      return h(ErrDialogCom, { title, msg });
    },
  });

  document.body.appendChild(node);
  instance.mount(node);
};

// 关闭
const close = () => {
  if (node) {
    document.body.removeChild(node);
    node = null;
  }
};

export default {
  open,
  close,
};
