/**
 * @name: index
 * @author: itmobai
 * @date: 2022-11-02 15:27
 * @description：index
 * @update: 2022-11-02 15:27
 */
import { get, put } from '@/request'
import apiConfig from '@/config'
// 接口前缀
const apiPrefix = `/farm-system/${apiConfig.apiMode}`

/**
 * 获取用户信息
 * @param data 登录参数
 */
export const userInfoApi = () => get({url: `${apiPrefix}/user/getInfo`})
/**
 * 获取后端配置信息
 * @param configId
 */
export const getConfigApi = (configId: string) => get({url: `${apiPrefix}/config/configKey/${configId}`})

/**
 * 修改密码
 * @param oldPwd 旧密码
 * @param newPwd 新密码
 */
export const updatePwdApi = (oldPwd: string, newPwd: string) => put({url: `${apiPrefix}/user/profile/updatePwd?oldPassword=${oldPwd}&newPassword=${newPwd}`})
