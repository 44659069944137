/**
 * @name: prod
 * @author: itmobai
 * @date: 2022-11-02 13:36
 * @description：prod
 * @update: 2022-11-02 13:36
 */
import { IConfig } from '@/type/config'

const prod: IConfig = {
  apiUrl: "/api",
  mode: "正式环境",
  appVersion: "1.0.0",
  apiMode: "prod"
}

export default prod;
