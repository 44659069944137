/**
 * @name: index
 * @author: itmobai
 * @date: 2022-11-07 16:37
 * @description：index
 * @update: 2022-11-07 16:37
 */
// 单位
const units = [
  {
    label: 'KG',
    value: '0'
  },
  {
    label: '市斤',
    value: '1'
  },
  {
    label: '罐',
    value: '2'
  },
  {
    label: '个',
    value: '3'
  },
  {
    label: '盒',
    value: '4'
  },
  {
    label: '包',
    value: '5'
  },
  {
    label: '件',
    value: '6'
  },
  {
    label: '瓶',
    value: '7'
  },
  {
    label: '袋',
    value: '8'
  },
  {
    label: '听',
    value: '9'
  },
  {
    label: '箱',
    value: '10'
  },
  {
    label: '片',
    value: '11'
  }
]

export default {
  units
}
