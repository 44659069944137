import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Login from "@/layouts/Login.vue";
import Sec from "@/layouts/SecondaryScreen.vue";
import PosLogin from "@/layouts/PosLogin.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/layouts/Home.vue"),
    children: [
      {
        path: "index",
        name: "Index",
        component: () => import("@/views/index/index.vue")
      },
      {
        path: "list",
        name: "List",
        component: () => import("@/views/list/index.vue")
      }
    ]
  },
  {
    path: "/sec",
    name: "Sec",
    component: Sec
  },
  {
    path: "/poslogin",
    name: "PosLogin",
    component: PosLogin
  },
  {
    path: "/poslist",
    name: "PosList",
    component: () => import("@/layouts/PosList.vue")
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
