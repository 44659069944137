import { h, createApp } from "vue";
import LoadingCom from "./Loading.vue";

let node: any;

// 打开
const open = () => {
  if (node) {
    document.body.removeChild(node);
  }
  node = document.createElement("div");

  const instance = createApp({
    render() {
      return h(LoadingCom);
    },
  });

  document.body.appendChild(node);
  instance.mount(node);
};

// 关闭
const close = () => {
  if (node) {
    document.body.removeChild(node);
    node = null;
  }
};

export default {
  open,
  close,
};
