/**
 * @name: index
 * @author: itmobai
 * @date: 2022-11-02 18:29
 * @description：index
 * @update: 2022-11-02 18:29
 */
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import storage from '@/storage'
import { EAutoPrint, EWeightUnit } from '@/type/setting'
import { getConfigApi } from '@/apis/system'

export const useSettingStore = defineStore("SETTING", () => {
  const ipc = window.androidIpcRenderer || window.ipcRenderer;
  // 单位：0 KG 1 市斤
  const isKGState = ref<EWeightUnit>(1);
  // 自动打印：1 是 0 否
  const autoPrintState = ref<EAutoPrint>(0);
  // 精确度：2 2位小数
  const precisionState = ref(2);
  // 重量单位
  const isKG = computed(() => (isKGState.value == null || isKGState.value == undefined) ? storage.getData("isKG") : isKGState.value);
  // 自动打印
  const autoPrint = computed(() => (autoPrintState.value == null || autoPrintState.value == undefined) ? storage.getData("autoPrint") : autoPrintState.value);
  // 精确度
  const precision = computed(() => precisionState.value || storage.getData("precision"))
  // 设备码
  const deviceNo = ref("")

  // 设备码
  const equipmentCode = computed(() => {
    let ipcDeviceId = ipc?.sendSync("getDeviceId")
    if(process.env.NODE_ENV == "development") {
      // deviceNo.value = "EEoJnmaa"
    }
    if(!deviceNo.value && !ipcDeviceId) {
      return ""
    }
    if(ipcDeviceId) {
      deviceNo.value = ipcDeviceId;
    }
    return deviceNo.value || ipcDeviceId
  })

  /**
   * 获取字典配置
   * @param configId 配置id
   */
  const getConfigAction = async (configId: string) => {
    return await getConfigApi(configId);
  }

  /**
   * 保存设置
   */
  const setSettingAction = (data: any) => {
    isKGState.value = data.isKG;
    autoPrintState.value = data.autoPrint;
    precisionState.value = data.precision;
    storage.setData("isKG", data.isKG);
    storage.setData("autoPrint", data.autoPrint);
    storage.setData("precision", data.precision);
  }

  return {
    isKGState,
    autoPrintState,
    precisionState,
    isKG,
    autoPrint,
    precision,
    deviceNo,
    equipmentCode,
    getConfigAction,
    setSettingAction
  }
})
