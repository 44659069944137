/**
 * @name: index
 * @author: itmobai
 * @date: 2022-11-02 16:07
 * @description：index
 * @update: 2022-11-02 16:07
 */
import { defineStore } from 'pinia'
import { payPicApi, stallListApi } from '@/apis/core'
import { ref } from 'vue'
import { IStall } from '@/type/stall'

export const useStallStore = defineStore("STALL", () => {
  // 选择的档口对象
  const stallObj = ref<IStall>({})

  /**
   * 获取档口列表
   */
  const stallListAction = async () => {
    return await stallListApi();
  }

  /**
   * 设置档口对象
   * @param data 档口对象
   */
  const setStallAction = async (data: IStall) => {
    stallObj.value = data;
  }

  /**
   * 获取二维码信息
   * @param stallId
   */
  const getPayPicAction = async (stallId: string) => {
    return await payPicApi(stallId);
  }

  return {
    stallObj,
    setStallAction,
    stallListAction,
    getPayPicAction
  }
})
