/**
 * @name: index
 * @author: itmobai
 * @date: 2022-11-07 19:41
 * @description：index
 * @update: 2022-11-07 19:41
 */
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { IMerchant } from '@/type/merchant'

export const useMerchantStore = defineStore("MERCHANT", () => {
  // 商户信息
  const merchantObj = ref<IMerchant>({})
  /**
   * 设置商户信息
   * @param obj
   */
  const setMerchantAction = (obj: IMerchant) => {
    merchantObj.value = obj;
  }

  return {
    merchantObj,
    setMerchantAction
  }
})
