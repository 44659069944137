/**
 * @name: index
 * @author: itmobai
 * @date: 2022-11-02 13:34
 * @description：index
 * @update: 2022-11-02 13:34
 */
import { IConfig } from '@/type/config'
import dev from '@/config/dev'
import test from '@/config/test'
import prod from '@/config/prod'

let obj: IConfig;

if(process.env.NODE_ENV == "development") {
  obj = dev;
} else if(process.env.NODE_ENV == "test") {
  obj = test;
} else {
  obj = prod;
}

export default obj;
