/**
 * @name: index
 * @author: itmobai
 * @date: 2022-11-02 11:53
 * @description：index
 * @update: 2022-11-02 11:53
 */
import axios from "axios";
import type { AxiosRequestConfig, AxiosResponse } from "axios";
import type { IResponse } from "@/type/axios/response";
import myConfig from "@/config/index";
import { useUserStore } from "@/store/user";
import { useSettingStore } from '@/store/setting'
import Confirm from "@/components/Confirm";
import Loading from "@/components/Loading";

const ipc = window.androidIpcRenderer || window.ipcRenderer;

// 创建axios实例
const instance = axios.create({
  baseURL: myConfig.apiUrl,
  timeout: 10 * 1000,
});

// 请求头拦截器
instance.interceptors.request.use(
  (config) => {
    const userStore = useUserStore();
    const settingStore = useSettingStore()
    if (userStore.token) {
      config.headers!["Authorization"] = "Bearer " + userStore.token;
    }
    if(settingStore.equipmentCode) {
      // @ts-ignore
      config.headers!["deviceNo"] = settingStore.equipmentCode;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (res) => {
    if (res.status == 200 && res.data && res.data.code == 200) {
      return res.data;
    }
    if (res.data) {
      if (res.data.code == 401) {
        // 登录
        window.location.href = "/#/";
        const arg = {
          type: "loading",
        };
        ipc?.send(
          "changeSec",
          window.androidIpcRenderer ? JSON.stringify(arg) : arg
        );
      } else {
        Confirm.open("系统提示", res.data.msg);
      }
    }
    Loading.close()
    return Promise.reject(res.data);
  },
  (err) => {
    Loading.close();
    const { response } = err;
    let title = "服务器发生异常，请联系管理员";
    if(response) {
      title = response;
    }
    Confirm.open("系统提示", title);
    return Promise.reject();
  }
);

const request = (config: AxiosRequestConfig): Promise<any> => {
  const conf = config;
  return new Promise((resolve, reject) => {
    instance
      .request<any, AxiosResponse<IResponse>>(conf)
      .then((res: AxiosResponse<IResponse>) => {
        // resolve(res as unknown as Promise<T>);
        // const { data } = res;
        // const data: T = res
        // console.log(res)
        resolve((res.data || res) as any);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * get 请求
 * @param config 配置项
 * @returns
 */
export const get = <T = any>(config: AxiosRequestConfig): Promise<T> => {
  return request({ ...config, method: "GET" });
};

/**
 * post application/json 请求
 * @param config 配置项
 * @returns
 */
export const postJ = <T = any>(config: AxiosRequestConfig): Promise<T> => {
  const headers = config.headers
    ? Object.assign(config.headers, { "Content-Type": "application/json" })
    : { "Content-Type": "application/json" };
  return request({ ...config, method: "POST", headers });
};

/**
 * post x-www-form-urlencoded 请求
 * @param config
 * @returns
 */
export const postW = <T = any>(config: AxiosRequestConfig): Promise<T> => {
  const headers = config.headers
    ? Object.assign(config.headers, {
      "Content-Type": "application/x-www-form-urlencoded",
    })
    : { "Content-Type": "application/x-www-form-urlencoded" };
  return request({ ...config, method: "POST", headers });
};

/**
 * put 请求
 * @param config
 * @returns
 */
export const put = <T = any>(config: AxiosRequestConfig): Promise<T> => {
  return request({ ...config, method: "PUT" });
};

/**
 * delete 请求
 * @param config
 * @returns
 */
export const del = <T = any>(config: AxiosRequestConfig): Promise<T> => {
  return request({ ...config, method: "DELETE" });
};

export default request;
